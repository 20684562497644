var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-img", {
    staticClass: "hover-icon w-20 hidden",
    class: {
      bright: _vm.isServicePayableId,
      dim: !_vm.isServicePayableId && (_vm.isHover || _vm.isLocalHover),
    },
    attrs: { src: _vm.isRemove ? _vm.removeSrc : _vm.selectedSrc },
    on: {
      mouseover: function ($event) {
        return _vm.setLocalHover(true)
      },
      mouseout: function ($event) {
        return _vm.setLocalHover(false)
      },
      click: _vm.handleClick,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }