var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "services-page-component mobile-friendly" },
    [
      _c("service-search-filters-form", {
        attrs: {
          "cancel-services": _vm.cancelServices,
          datasource: _vm.datasource,
          fields: _vm.fields,
          "filter-options": _vm.filterOptions,
          "on-submit": _vm.filtersSubmit,
          "multi-company-selection": _vm.multiCompanySelection,
          "search-changed": _vm.searchChanged,
          "hide-cancelled": _vm.hideCancelled,
        },
        on: {
          "update:hideCancelled": function ($event) {
            _vm.hideCancelled = $event
          },
          "update:hide-cancelled": function ($event) {
            _vm.hideCancelled = $event
          },
        },
      }),
      _c(
        "ajax-table",
        _vm._g(
          {
            ref: "servicesTable",
            staticClass: "services-table",
            attrs: {
              "table-definition": _vm.tableDefinition,
              "should-enable-checkbox": _vm.determineSelectable,
              "selectable-function": _vm.isSelectable,
              selectable: true,
              expandable: false,
              outlined: true,
            },
            on: { "selection-changed": _vm.selectionEvent, loaded: _vm.onLoad },
            scopedSlots: _vm._u([
              {
                key: "cell(product)",
                fn: function (row) {
                  return [
                    row.item.product.registered_agent
                      ? _c("span", [
                          row.item.registered_agent_name
                            ? _c("span", [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(row.item.registered_agent_name) +
                                    "\n        "
                                ),
                              ])
                            : _vm._e(),
                          _c("br"),
                          row.item.registered_agent_address
                            ? _c("span", [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      row.item.registered_agent_address
                                        .formatted
                                    ) +
                                    "\n          "
                                ),
                                row.item.registered_agent_address.county
                                  ? _c("span", [
                                      _c("br"),
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            row.item.registered_agent_address
                                              .county
                                          ) +
                                          "\n          "
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _c("br"),
                          row.item.status !== "cancelled" &&
                          row.item.product &&
                          row.item.registered_agent_address &&
                          row.item.registered_agent_name
                            ? _c(
                                "a",
                                {
                                  staticClass: "ra-details-link",
                                  on: {
                                    click: function ($event) {
                                      return _vm.presentRegisteredAgentDetails(
                                        row.item
                                      )
                                    },
                                  },
                                },
                                [_vm._v("More Details")]
                              )
                            : _vm._e(),
                          _c("br"),
                          row.item.product.upload_limit &&
                          row.item.status === "active"
                            ? _c("span", [
                                _c("em", [
                                  _vm._v(
                                    "Document Limit: " +
                                      _vm._s(row.item.product.upload_limit)
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ])
                      : row.item.type === "mail-forwarding" &&
                        row.item.status === "active"
                      ? _c("span", [
                          row.item.mail_forwarding_address && row.item.company
                            ? _c("span", [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(row.item.company.name) +
                                    "\n        "
                                ),
                              ])
                            : _vm._e(),
                          _c("br"),
                          _vm.shouldDisplayAddressWithSuiteNumber(row.item) ||
                          _vm.isFreeLimitedUse(row.item)
                            ? _c("span", [
                                _c("span", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.addressLine1Format(row.item)) +
                                      ",\n          "
                                  ),
                                ]),
                                _c("br"),
                                _c("span", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        row.item.mail_forwarding_address.city
                                      ) +
                                      ", " +
                                      _vm._s(
                                        row.item.mail_forwarding_address
                                          .state_province_region
                                      ) +
                                      " " +
                                      _vm._s(
                                        row.item.mail_forwarding_address
                                          .zip_postal_code
                                      ) +
                                      ", " +
                                      _vm._s(
                                        row.item.mail_forwarding_address.country
                                      ) +
                                      "\n          "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _c("br"),
                          row.item.product.upload_limit
                            ? _c("span", [
                                _c("em", [
                                  _vm._v(
                                    "Document Limit: " +
                                      _vm._s(row.item.product.upload_limit)
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ])
                      : _c("span", [_vm._v("—")]),
                  ]
                },
              },
              {
                key: "cell(start)",
                fn: function (row) {
                  return [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.dateTableColumn(row.item.start)) +
                        " to"
                    ),
                    _c("br"),
                    _vm._v(
                      _vm._s(_vm.dateTableColumn(row.item.stop)) + "\n    "
                    ),
                  ]
                },
              },
              {
                key: "cell(product.price)",
                fn: function (row) {
                  return [
                    _c("span", [_vm._v("$" + _vm._s(row.item.renewal_price))]),
                    _c("br"),
                    row.item.product.duration
                      ? _c("span", [
                          _vm._v(
                            _vm._s(row.item.product.duration) +
                              " " +
                              _vm._s(
                                row.item.product.duration === 1
                                  ? "Month"
                                  : "Months"
                              )
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(autopay_payable_id)",
                fn: function (row) {
                  return [
                    _vm.isSelectable(row.item) || _vm.isSelectableAlt(row.item)
                      ? _c(
                          "div",
                          {
                            ref: "autopay-select-" + row.index,
                            staticClass: "autopay-select",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "autopay-dropdown-section" },
                              [
                                _c("select-auto-payable", {
                                  staticClass: "autopay-dropdown",
                                  attrs: {
                                    "selected-autopay-id":
                                      row.item.autopay_payable_id,
                                    disabled: !_vm.autopayPayableSelectable(
                                      row.item.status
                                    ),
                                    "is-v-i-p": _vm.isVIPBundle(row.item),
                                  },
                                  on: {
                                    dropdownItemSelected: function ($event) {
                                      return _vm.autopayPayableSelectHandler(
                                        $event,
                                        row
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          },
          _vm.$listeners
        )
      ),
      _c("cancellation-modal", {
        attrs: { bus: _vm.bus },
        on: { "cancel-complete": _vm.refresh },
      }),
      _c("update-auto-pay-modal", {
        ref: `update-autopay-modal-${_vm.thisUid}`,
        attrs: { "selected-service": _vm.selection },
        on: {
          "accept-autopay-warning": _vm.processAutopayUpdates,
          "reject-autopay-warning": _vm.reloadServicesTable,
        },
      }),
      _c("payment-method-modal", {
        ref: `payment-method-modal-${_vm.thisUid}`,
        on: {
          update: _vm.addPaymentMethodHandler,
          hide: _vm.reloadServicesTable,
        },
      }),
      _c("registered-agent-details-modal", {
        ref: `registered-agent-details-modal-${_vm.thisUid}`,
        attrs: { interaction: _vm.interaction, "ra-service": _vm.raItem },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }