<template>
  <b-img
    class="hover-icon w-20 hidden"
    :class="{'bright': isServicePayableId, 'dim': !isServicePayableId && (isHover || isLocalHover)}"
    :src="isRemove ? removeSrc : selectedSrc"
    @mouseover="setLocalHover(true)"
    @mouseout="setLocalHover(false)"
    @click="handleClick"
  />
</template>

<script>

/**
 * autopay selection options, displays condensed payment logo/info and hover icon
 *
 * The hover icon indicates selected payment method for a service or assign/remove CTA's
 *
 * 1. Displays as bright green check circle, if this is the assigned autopay of the service
 *  - Indicates already assigned and not hovered on currently
 * 2. Toggles displaying dim green check circle, if this row is hovered and is not the assigned autopay
 *  - Indicates can assign the payable as the service autopay
 * 3. Toggles displaying dim red x circle, if this row is hovered and is the assigned autopay
 *  - Indicates can remove the payable as the assigned autopay
 */
export default {
  name: 'AutoPayHoverIcon',
  components: {},
  props: {
    last4: String,
    logoSrc: String,
    onAssignCallback: {
      type: Function,
      default: () => {},
    },
    onRemoveCallback: {
      type: Function,
      default: () => {},
    },
    payableType: String,
    servicePayableId: { // payable id assigned to service's auto pay
      type: String,
      default: null,
    },
    selfPayable: { // payable of this particular dropdown selection option
      type: Object,
      default: null,
    },
    hoverId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      achLogoSrc: null,
      cardLogoSrc: null,
      selectedSrc: '/images/payments/check-circle.svg',
      removeSrc: '/images/payments/x-circle.svg',
      isLocalHover: false,
    }
  },
  computed: {
    isServicePayableId() {
      return this.selfPayable.id === this.servicePayableId
    },
    isRemove() {
      return this.isLocalHover && this.isServicePayableId
    },
    assignPayable () {
      return this.isRemove ? null : this.selfPayable
    },
    isHover() {
      return this.selfPayable.id === this.hoverId
    },
  },
  methods: {
    handleClick() {
      if (this.isRemove) {
        this.onRemoveCallback()
      } else if (!this.isServicePayableId) {
        this.onAssignCallback(this.assignPayable, this.payableType)
      }
    },
    setLocalHover(isLocalHover) {
      this.isLocalHover = isLocalHover
    },
  },
}
</script>
<style scoped lang="scss">
  .hidden {
    opacity: 0; // avoids hover issues with parent, instead of using v-if/v-show
  }
  .bright {
    opacity: 1 !important;
  }
  .dim {
    opacity: .3 !important;
  }
  .hover-icon {
    position: absolute;
    right: 20px;
    cursor: pointer !important;
    padding: 0 !important;
    margin: 0 !important;
  }
</style>
