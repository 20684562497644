var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column align-content-start" },
    [
      _c("h2", { staticClass: "mt-0 title", attrs: { "data-cy": "title" } }, [
        _vm._v("\n    Services\n  "),
      ]),
      _c(
        "b-row",
        {
          staticClass: "w-100 d-flex justify-content-start",
          attrs: { "align-v": "center" },
        },
        [
          _c(
            "b-col",
            { staticClass: "mt-2", attrs: { lg: "4", md: "12", sm: "12" } },
            [
              _c("search", {
                attrs: {
                  "show-search-input": true,
                  datasource: _vm.datasource,
                  fields: _vm.fields,
                  "filter-options": _vm.filterOptions,
                  mq: _vm.$mq,
                },
                on: { changed: _vm.searchBarChanged },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticClass: "d-flex mt-2",
              attrs: { cols: "12", lg: "8", xl: "8" },
            },
            [
              _c(
                "div",
                { staticClass: "flex-fill pr-2" },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle.collapse-filters",
                          modifiers: { "collapse-filters": true },
                        },
                      ],
                      staticClass: "filter-btn",
                      attrs: {
                        id: "search-filters-btn",
                        variant: "secondary",
                        "aria-label": "search filters button",
                      },
                      on: { click: _vm.toggleArrowUpDisplay },
                    },
                    [
                      _vm._v("\n          Search Filters\n          "),
                      _c("div", {
                        staticClass: "hidden",
                        attrs: { id: "arrow-up" },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "filter-btn mr-2",
                  attrs: {
                    size: "sm",
                    variant: "primary",
                    "aria-label": "add services button",
                  },
                },
                [
                  _c(
                    "router-link",
                    {
                      staticStyle: {
                        color: "white",
                        "text-decoration": "none",
                      },
                      attrs: { to: "/hire-us" },
                    },
                    [_vm._v("\n          Add Services\n        ")]
                  ),
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "filter-btn",
                  attrs: {
                    size: "sm",
                    variant: "danger",
                    "aria-label": "cancel services button",
                    disabled: _vm.multiCompanySelection.length === 0,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.cancelServices()
                    },
                  },
                },
                [_vm._v("\n        Cancel Services\n      ")]
              ),
            ],
            1
          ),
          _c(
            "b-collapse",
            { staticClass: "w-100", attrs: { id: "collapse-filters" } },
            [
              _c(
                "div",
                {
                  staticClass:
                    "w-100 d-flex flex-wrap align-items-center justify-content-center filters-container",
                },
                [
                  _c(
                    "b-row",
                    { staticClass: "w-100 d-flex justify-content-center mb-2" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "filter-column",
                          attrs: { xl: "2", lg: "3", md: "3", cols: "6" },
                        },
                        [
                          _c("company-select", {
                            staticClass: "filter-option",
                            attrs: {
                              placeholder: "Company",
                              "reset-company": _vm.resetCompany,
                            },
                            on: {
                              input: _vm.companySelectHandler,
                              resetCompany: _vm.resetCompanyHandler,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "filter-column",
                          attrs: { xl: "2", lg: "3", md: "3", cols: "6" },
                        },
                        [
                          _c("jurisdiction-select", {
                            staticClass: "filter-option",
                            attrs: {
                              "is-disable": false,
                              placeholder: "Jurisdiction",
                              "reset-jurisdiction": _vm.resetJurisdiction,
                            },
                            on: {
                              input: _vm.jurisdictionSelectHandler,
                              resetJurisdiction: _vm.resetJurisdictionHandler,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "filter-column",
                          attrs: { xl: "2", lg: "3", md: "3", cols: "6" },
                        },
                        [
                          _c("multi-select", {
                            staticClass: "filter-option",
                            attrs: {
                              label: "name",
                              "track-by": "value",
                              options: _vm.serviceTypeOptions,
                              placeholder: "Service Type",
                              "deselect-label": "",
                              "select-label": "",
                            },
                            on: { input: _vm.serviceTypeSelectHandler },
                            model: {
                              value: _vm.serviceType,
                              callback: function ($$v) {
                                _vm.serviceType = $$v
                              },
                              expression: "serviceType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "filter-column",
                          attrs: { xl: "2", lg: "3", md: "3", cols: "6" },
                        },
                        [
                          _c("multi-select", {
                            staticClass: "filter-option",
                            attrs: {
                              label: "name",
                              "track-by": "value",
                              options: _vm.statusTypeOptions,
                              placeholder: "Status Type",
                              "deselect-label": "",
                              "select-label": "",
                            },
                            on: { input: _vm.statusTypeSelectHandler },
                            model: {
                              value: _vm.statusType,
                              callback: function ($$v) {
                                _vm.statusType = $$v
                              },
                              expression: "statusType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "filter-column",
                          attrs: { xl: "2", lg: "3", md: "3", cols: "6" },
                        },
                        [
                          _c("b-form-datepicker", {
                            staticClass: "filter-btn",
                            attrs: {
                              placeholder: "Start Date",
                              size: "sm",
                              locale: "en",
                              "value-as-date": true,
                              dark: false,
                              "no-flip": true,
                            },
                            on: { input: _vm.startDateSelectHandler },
                            model: {
                              value: _vm.startDate,
                              callback: function ($$v) {
                                _vm.startDate = $$v
                              },
                              expression: "startDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "filter-column",
                          attrs: { xl: "2", lg: "3", md: "3", cols: "6" },
                        },
                        [
                          _c("b-form-datepicker", {
                            staticClass: "filter-btn",
                            attrs: {
                              placeholder: "End Date",
                              size: "sm",
                              locale: "en",
                              "value-as-date": true,
                              dark: false,
                              "no-flip": true,
                            },
                            on: { input: _vm.stopDateSelectHandler },
                            model: {
                              value: _vm.endDate,
                              callback: function ($$v) {
                                _vm.endDate = $$v
                              },
                              expression: "endDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass:
                        "w-100 d-flex justify-content-end align-items-center",
                    },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "filter-column d-flex justify-content-end",
                          attrs: { xl: "6", lg: "6", md: "6", cols: "12" },
                        },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              model: {
                                value: _vm.localHideCancelled,
                                callback: function ($$v) {
                                  _vm.localHideCancelled = $$v
                                },
                                expression: "localHideCancelled",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "pr-1 font-size-sm font-weight-normal",
                                },
                                [_vm._v("Do not show cancelled services")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass:
                        "w-100 d-flex justify-content-end align-items-center",
                    },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "filter-column d-flex justify-content-center",
                          attrs: { xl: "2", lg: "3", md: "3", cols: "12" },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "filter-btn",
                              attrs: {
                                id: "reset-filters-btn",
                                size: "sm",
                                variant: "secondary",
                                "aria-label": "reset filters button",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.resetFilters()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n              Reset Filters\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }