<template>
  <div class="d-flex flex-column align-content-start">
    <h2 data-cy="title" class="mt-0 title">
      Services
    </h2>
    <b-row align-v="center" class="w-100 d-flex justify-content-start">
      <b-col class="mt-2" lg="4" md="12" sm="12">
        <search
          :show-search-input="true"
          :datasource="datasource"
          :fields="fields"
          :filter-options="filterOptions"
          :mq="$mq"
          @changed="searchBarChanged"
        />
      </b-col>

      <b-col class="d-flex mt-2" cols="12" lg="8" xl="8">
        <div class="flex-fill pr-2">
          <b-button
            id="search-filters-btn"
            v-b-toggle.collapse-filters
            variant="secondary"
            class="filter-btn"
            aria-label="search filters button"
            @click="toggleArrowUpDisplay"
          >
            Search Filters
            <div id="arrow-up" class="hidden" />
          </b-button>
        </div>
        <b-button
          class="filter-btn mr-2"
          size="sm"
          variant="primary"
          aria-label="add services button"
        >
          <router-link
            to="/hire-us"
            style="color: white; text-decoration: none;"
          >
            Add Services
          </router-link>
        </b-button>

        <b-button
          class="filter-btn"
          size="sm"
          variant="danger"
          aria-label="cancel services button"
          :disabled="multiCompanySelection.length === 0"
          @click="cancelServices()"
        >
          Cancel Services
        </b-button>
      </b-col>
      <b-collapse id="collapse-filters" class="w-100">
        <div class="w-100 d-flex flex-wrap align-items-center justify-content-center filters-container">
          <b-row class="w-100 d-flex justify-content-center mb-2">
            <b-col class="filter-column" xl="2" lg="3" md="3" cols="6">
              <company-select
                class="filter-option"
                :placeholder="'Company'"
                @input="companySelectHandler"
                :reset-company="resetCompany"
                @resetCompany="resetCompanyHandler"
              />
            </b-col>
            <b-col class="filter-column" xl="2" lg="3" md="3" cols="6">
              <jurisdiction-select
                class="filter-option"
                :is-disable="false"
                :placeholder="'Jurisdiction'"
                @input="jurisdictionSelectHandler"
                :reset-jurisdiction="resetJurisdiction"
                @resetJurisdiction="resetJurisdictionHandler"
              />
            </b-col>
            <b-col class="filter-column" xl="2" lg="3" md="3" cols="6">
              <multi-select
                v-model="serviceType"
                class="filter-option"
                label="name"
                track-by="value"
                :options="serviceTypeOptions"
                placeholder="Service Type"
                @input="serviceTypeSelectHandler"
                deselect-label=""
                select-label=""
              />
            </b-col>
            <b-col class="filter-column" xl="2" lg="3" md="3" cols="6">
              <multi-select
                v-model="statusType"
                class="filter-option"
                label="name"
                track-by="value"
                :options="statusTypeOptions"
                placeholder="Status Type"
                @input="statusTypeSelectHandler"
                deselect-label=""
                select-label=""
              />
            </b-col>
            <b-col class="filter-column" xl="2" lg="3" md="3" cols="6">
              <b-form-datepicker
                v-model="startDate"
                placeholder="Start Date"
                class="filter-btn"
                size="sm"
                locale="en"
                :value-as-date="true"
                :dark="false"
                :no-flip="true"
                @input="startDateSelectHandler"
              />
            </b-col>
            <b-col class="filter-column" xl="2" lg="3" md="3" cols="6">
              <b-form-datepicker
                v-model="endDate"
                placeholder="End Date"
                class="filter-btn"
                size="sm"
                locale="en"
                :value-as-date="true"
                :dark="false"
                :no-flip="true"
                @input="stopDateSelectHandler"
              />
            </b-col>
          </b-row>
          <b-row class="w-100 d-flex justify-content-end align-items-center">
            <b-col class="filter-column d-flex justify-content-end" xl="6" lg="6" md="6" cols="12">
              <b-form-checkbox v-model="localHideCancelled">
                <span class="pr-1 font-size-sm font-weight-normal">Do not show cancelled services</span>
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row class="w-100 d-flex justify-content-end align-items-center">
            <b-col class="filter-column d-flex justify-content-center" xl="2" lg="3" md="3" cols="12">
              <b-button
                id="reset-filters-btn"
                class="filter-btn"
                size="sm"
                variant="secondary"
                aria-label="reset filters button"
                @click="resetFilters()"
              >
                Reset Filters
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-collapse>
    </b-row>
  </div>
</template>

<script>
  import _ from 'lodash'
  import CompanySelect from '@/components/CompanySelect'
  import JurisdictionSelect from '@/components/JurisdictionSelect'
  import MultiSelect from 'vue-multiselect'
  import Search from '@/components/shared/Search'

  export default {
    name: 'ServiceSearchFiltersForm',
    components: { CompanySelect, JurisdictionSelect, MultiSelect, Search },
    props: {
      cancelServices: {
        type: Function,
        default: () => { return true },
      },
      datasource: {
        type: Array,
        default: () => [],
      },
      fields: {
        type: Array,
        default: () => [],
      },
      filterOptions: {
        type: Object,
        default: () => {},
      },
      onSubmit: {
        type: Function,
        default: () => { return true },
      },
      multiCompanySelection: {
        type: Array,
        default: () => [],
      },
      searchChanged: {
        type: Function,
        default: () => { return true },
      },
      hideCancelled: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        query: {},
        serviceType: '',
        statusType: '',
        resetJurisdiction: false,
        resetCompany: false,
        localHideCancelled: this.hideCancelled,
        startDate: '',
        endDate: '',
      }
    },
    computed: {
      serviceTypeOptions() {
        return [
          {
            name: 'Blanket Agent Service',
            value: 'blanket-agent',
          },
          {
            name: 'Call Forwarding',
            value: 'call-forwarding',
          },
          {
            name: 'Compliance',
            value: 'compliance',
          },
          {
            name: 'Law On Call',
            value: 'law-on-call',
          },
          {
            name: 'Local DC Agent',
            value: 'local-dc-agent',
          },
          {
            name: 'Mail Forwarding',
            value: 'mail-forwarding',
          },
          {
            name: 'Registered Agent',
            value: 'registered-agent',
          },
        ]
      },
      statusTypeOptions() {
        return [
          {
            name: 'Active',
            value: 'active',
          },
          {
            name: 'Awaiting Client Input',
            value: 'awaiting-client-input',
          },
          {
            name: 'Cancelled',
            value: 'cancelled',
          },
          {
            name: 'Cancel Requested',
            value: 'cancel-requested',
          },
          {
            name: 'New',
            value: 'new',
          },
          {
            name: 'Trial Active',
            value: 'trial-active',
          },
          {
            name: 'Waiting On Information',
            value: 'waiting on information',
          },
          {
            name: 'Waiting On Payment',
            value: 'waiting on payment',
          },
        ]
      },
    },
    watch: {
      localHideCancelled(newValue) {
        this.$emit('update:hideCancelled', newValue)
        this.debouncedSubmit()
      },
    },
    methods: {
      companySelectHandler(selection) {
        this.query.company_id = selection
        this.debouncedSubmit()
      },
      jurisdictionSelectHandler(selection) {
        this.query.jurisdiction_id = selection
        this.debouncedSubmit()
      },
      resetFilters() {
        this.query = {}
        this.serviceType = ''
        this.statusType = ''
        this.localHideCancelled = true
        this.startDate = ''
        this.endDate = ''
        this.resetJurisdictionHandler(true)
        this.resetCompanyHandler(true)
        this.debouncedSubmit()
      },
      resetJurisdictionHandler(selection = false) {
        this.resetJurisdiction = selection
      },
      resetCompanyHandler(selection = false) {
        this.resetCompany = selection
      },
      serviceTypeSelectHandler(selection) {
        this.query.service_type = selection.value
        this.debouncedSubmit()
      },
      searchBarChanged(queryObject) {
        this.searchChanged(queryObject)
      },
      statusTypeSelectHandler(selection) {
        this.query.status_type = selection.value
        this.debouncedSubmit()
      },
      startDateSelectHandler(selection) {
        this.query.start = selection.getTime() / 1000
        this.debouncedSubmit()
      },
      stopDateSelectHandler(selection) {
        this.query.stop = selection.getTime() / 1000
        this.debouncedSubmit()
      },
      toggleArrowUpDisplay() {
        const arrow = document.getElementById('arrow-up')
        if (arrow.classList.contains('hidden')) {
          arrow.classList.remove('hidden')
        } else {
          arrow.classList.add('hidden')
        }
      },
      debouncedSubmit: _.debounce(function() {
        this.onSubmit(this.query)
      }, 100),
    },
  }
</script>

<style lang="scss" scoped >

  #reset-filters-btn { width: 100% !important; }

  #arrow-up {
    position: absolute;
    top: 100%;
    transform: translateX(100%);
    padding-bottom: 10px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #F1F1F1;
  }

  .title {
    margin-bottom: 40px;
  }

  .filters-container {
    padding: 1.5625em 0.375em 0.625em 0.375em;
    margin: 1.125em 0 0 0.9375em;
    border-radius: 8px;
    background: #F1F1F1;
  }

  .filter-column {
    padding: 0 0.375em;
  }

  .hidden { display: none; }

  .filter-column .font-size-sm {
    font-size: $font-size-sm;
  }

  .filter-column .filter-btn {
    margin: 0.3125em 0 0 0;
  }

  .filter-btn {
    font-size: 0.750em;
    width: 175px;
    min-width: 150px;
  }

  .filter-option {
    width: 98%;
    height: 48px !important;
    margin-top: 5px !important;
  }

  .collapsed > .when-open,
  .not-collapsed > .when-closed {
    display: none;
  }

  .collapsed > .when-open,
  .not-collapsed > .when-closed {
    display: none;
  }

  @media only screen and (max-width: 991px) {
    .filters-container {
      margin-top: 28px; // height adjustment to connect with triangle
    }
  }

</style>

<style lang="scss" >
  .text-muted {
    color: #adadad !important;
    font-size: 14px !important;
  }
  // datepicker calendar icon container
  .b-form-btn-label-control.form-control > .btn {
    padding: 0.5em 0.625em !important;
  }

  // datepicker btn label
  .b-form-btn-label-control.form-control > label {
    display: flex;
    align-items: center;
    padding: 0px !important;
    margin: 0px !important;
    height: auto !important;
    min-height: 40px !important;
    max-height: 40px !important;
  }

  // datepicker btn container
  .b-form-btn-label-control.form-control {
    border: 1px solid #E8E7E8 !important;
    padding: 0px !important;
    margin: 1.125em 0 0 0.9375em;
    width: 98%;
    height: auto !important;
    min-height: 43px !important;
    max-height: 43px !important;
  }

  // filter select menus
  #collapse-filters > div.filters-container > div:nth-child(1) > div.filter-column > div.multiselect > div.multiselect__tags {
    border: 1px solid #E8E7E8 !important;
  }

  //filter options wrapper
  #collapse-filters > div.filters-container > div:nth-child(1) > div.filter-column > div.multiselect > div.multiselect__content-wrapper {
    min-width: fit-content;
  }

  //company filter wrapper
  #collapse-filters > div.filters-container > div:nth-child(1) > div.filter-column > div.company-select > div.multiselect__content-wrapper {
    min-width: 28rem;
  }

</style>
