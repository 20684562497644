<template>
  <b-container class="services-page-component mobile-friendly">
    <service-search-filters-form
      :cancel-services="cancelServices"
      :datasource="datasource"
      :fields="fields"
      :filter-options="filterOptions"
      :on-submit="filtersSubmit"
      :multi-company-selection="multiCompanySelection"
      :search-changed="searchChanged"
      :hide-cancelled.sync="hideCancelled"
    />
    <ajax-table
      ref="servicesTable"
      class="services-table"
      :table-definition="tableDefinition"
      :should-enable-checkbox="determineSelectable"
      :selectable-function="isSelectable"
      :selectable="true"
      :expandable="false"
      :outlined="true"
      @selection-changed="selectionEvent"
      @loaded="onLoad"
      v-on="$listeners"
    >
      <!-- Service Details column -->
      <template v-slot:cell(product)="row">
        <!-- Registered Agent-->
        <span v-if="row.item.product.registered_agent">
          <span v-if="row.item.registered_agent_name">
            {{ row.item.registered_agent_name }}
          </span>
          <br>
          <span v-if="row.item.registered_agent_address">
            {{ row.item.registered_agent_address.formatted }}
            <span v-if="row.item.registered_agent_address.county">
              <br>
              {{ row.item.registered_agent_address.county }}
            </span>
          </span>
          <br>
          <a v-if="row.item.status !== 'cancelled' && row.item.product && row.item.registered_agent_address && row.item.registered_agent_name"
             class="ra-details-link" @click="presentRegisteredAgentDetails(row.item)">More Details</a>
          <br>
          <span v-if="row.item.product.upload_limit && row.item.status === 'active'">
            <em>Document Limit: {{ row.item.product.upload_limit }}</em>
          </span>
        </span>
        <!-- Mail Forwarding-->
        <span v-else-if="row.item.type === 'mail-forwarding' && row.item.status === 'active'">
          <span v-if="row.item.mail_forwarding_address && row.item.company">
            {{ row.item.company.name }}
          </span>
          <br>
          <span v-if="shouldDisplayAddressWithSuiteNumber(row.item) || isFreeLimitedUse(row.item)">
            <!--              SOPS-4758 Will need this in the future-->
            <!--              <span v-if="isFreeLimitedUse(row.item)">-->
            <!--                c/o {{ row.item.mail_forwarding_ra.name }}-->
            <!--                <br>-->
            <!--              </span>-->
            <span>
              {{ addressLine1Format(row.item) }},
            </span>
            <br>
            <span>
              {{ row.item.mail_forwarding_address.city }}, {{ row.item.mail_forwarding_address.state_province_region }} {{ row.item.mail_forwarding_address.zip_postal_code }}, {{ row.item.mail_forwarding_address.country }}
            </span>
          </span>
          <br>
          <span v-if="row.item.product.upload_limit">
            <em>Document Limit: {{ row.item.product.upload_limit }}</em>
          </span>
        </span>
        <span v-else>—</span>
      </template>
      <!-- start and stop range formatted Date column-->
      <template v-slot:cell(start)="row">
        {{ dateTableColumn(row.item.start) }} to<br>{{ dateTableColumn(row.item.stop) }}
      </template>
      <!-- product.price column -->
      <template v-slot:cell(product.price)="row">
        <span>${{ row.item.renewal_price }}</span>
        <br>
        <span v-if="row.item.product.duration">{{ row.item.product.duration }} {{ (row.item.product.duration === 1) ? "Month" : "Months" }}</span>
      </template>
      <!-- Autopay -->
      <template v-slot:cell(autopay_payable_id)="row">
        <div
          v-if="isSelectable(row.item) || isSelectableAlt(row.item)"
          :ref="'autopay-select-'+row.index"
          class="autopay-select"
        >
          <div class="autopay-dropdown-section">
            <select-auto-payable
              class="autopay-dropdown"
              :selected-autopay-id="row.item.autopay_payable_id"
              :disabled="!autopayPayableSelectable(row.item.status)"
              :is-v-i-p="isVIPBundle(row.item)"
              @dropdownItemSelected="autopayPayableSelectHandler($event, row)"
            />
          </div>
        </div>
      </template>
    </ajax-table>
    <cancellation-modal
      :bus="bus"
      @cancel-complete="refresh"
    />
    <update-auto-pay-modal
      :ref="`update-autopay-modal-${thisUid}`"
      :selected-service="selection"
      @accept-autopay-warning="processAutopayUpdates"
      @reject-autopay-warning="reloadServicesTable"
    />
    <payment-method-modal
      :ref="`payment-method-modal-${thisUid}`"
      @update="addPaymentMethodHandler"
      @hide="reloadServicesTable"
    />
    <registered-agent-details-modal
      :ref="`registered-agent-details-modal-${thisUid}`"
      :interaction="interaction"
      :ra-service="raItem"
    />
  </b-container>
</template>

<script>
import { companyServiceMixin } from '@/mixins/companyServiceMixin'
import { formatDateStringFromSeconds } from '@/common/modules/formatters'
import AjaxTable from '@/components/shared/AjaxTable'
import CancellationModal from '@/components/cancellation/CancellationModal'
import UpdateAutoPayModal from '../components/UpdateAutoPayModal'
import PaymentMethodModal from "@/components/PaymentMethodModal"
import ServiceSearchFiltersForm from '@/components/ServiceSearchFiltersForm'
import Vue from 'vue'
import SelectAutoPayable from '@/components/SelectAutoPayable'
import { mapGetters } from 'vuex'
import RegisteredAgentDetailsModal from '@/components/RegisteredAgent/RegisteredAgentDetailsModal'
import { OPERATING_STATUSES } from '@/common/modules/constants'

export default {
  name: 'WholesalerServices',
  components: {
    SelectAutoPayable,
    AjaxTable,
    CancellationModal,
    ServiceSearchFiltersForm,
    PaymentMethodModal,
    UpdateAutoPayModal,
    RegisteredAgentDetailsModal,
  },
  mixins: [companyServiceMixin],
  props: {
    datasource: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    filterOptions: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      bus: new Vue(),
      status: this.defaultStatuses(),
      multiCompanySelection: [],
      query: {},
      searchName: '',
      selection: [],
      selectedServiceIds: [],
      raItem: null,
      hideCancelled: true,
    }
  },
  computed: {
    ...mapGetters('paymentMethods', ['automatedClearingHouses', 'cards']),
    ...mapGetters('account', ['activeAccountServices']),
    tableDefinition() {
      return {
        columns: [
          { key: 'company.name', label: 'Name', thStyle: { width: "18%" }, sortable: true },
          { key: 'product.name', label: 'Service', thStyle: { width: "12%" }, sortable: true },
          { key: 'jurisdiction.state_province_region', label: 'Jurisdiction', thStyle: { width: "8%" }, sortable: true },
          { key: 'product', label: 'Service Details', thStyle: { width: "16%" }, sortable: true },
          { key: 'status', label: 'Status', formatter: value => this.capitalize(value), thStyle: { width: "8%" }, sortable: true },
          { key: 'product.price', label: 'Rate', thStyle: { width: "10%" }, sortable: true },
          { key: 'start', label: 'Date', thStyle: { width: "12%" }, sortable: true },
          { key: 'autopay_payable_id', label: 'Auto Pay', thStyle: { width: "12%" }, sortable: true },
        ],
        defaultOrderBy: 'type',
        defaultOrderDirection: 'asc',
        url: 'client/services',
        parameters: {
          status: this.status,
          include_company: true,
          include_jurisdiction: true,
          include_virtual_phone: true,
          include_mail_forwarding: true,
          vip_format: true,
          search_name: this.searchName,
          query: this.query,
        },
      }
    },
  },
  async mounted() {
    this.loaded = true
    this.showUnassignedAutopay()
  },
  methods: {
    async cancelServices() {
      this.bus.$emit('showCancelServices', this.multiCompanySelection)
    },
    capitalize(word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    },
    dateTableColumn(date) {
      return date === 'Invalid Date' ? '---' : formatDateStringFromSeconds(date)
    },
    deleteSelections(servicesIds) {
      this.multiCompanySelection = this.multiCompanySelection.filter(s => ! servicesIds.includes(s.id))
    },
    filtersSubmit(newQuery) {
      // Filter out `undefined` values from `newQuery`
      const filteredQuery = Object.fromEntries(
        Object.entries(newQuery).filter(([_key, value]) => value !== undefined)
      )

      // Compare the current query with the new filtered query
      const isQueryChanged = JSON.stringify(this.query) !== JSON.stringify(filteredQuery)

      if (this.hideCancelled) {
        this.status = this.defaultStatuses()
      } else {
        this.status = this.allStatuses()
      }

      if (isQueryChanged) {
        if (Object.keys(filteredQuery).length > 0) {
          // Filters are applied
          this.searchName = 'search_filtered_wholesaler'
          this.query = filteredQuery
        } else {
          // No filters applied
          this.searchName = ''
          this.query = {}
        }
      }
    },
    refresh(response) {
      this.multiCompanySelection = []
      this.selectedServiceIds = []
      this.$refs.servicesTable.reload()
      if (response.data.success) {
        this.$bvToast.toast(`${response.data.result.message}`, {
          title: 'Complete',
          variant: 'success',
          solid: true,
        })
      } else {
        this.$bvToast.toast(`${response.data.error.message}`, {
          title: 'Error',
          variant: 'danger',
          solid: true,
          noAutoHide: true,
        })
      }
    },
    searchChanged(queryObject) {
      this.searchName = 'search_wholesaler_company_names'
      this.query = queryObject.query
    },
    selectionEvent(selection) {
      let currentServiceIdsSelected = selection.map(s => s.id)
      // identify which rows were selected in this event emission
      let servicesToSelect = selection.filter(service => ! this.selectedServiceIds.includes(service.id))
      // identify which rows were unselected in this event emission
      let servicesIdsToUnselect = this.selectedServiceIds.filter(id => ! currentServiceIdsSelected.includes(id))
      if (servicesToSelect.length > 0) {
        this.multiCompanySelection = this.multiCompanySelection.concat(servicesToSelect)
      } else if (servicesIdsToUnselect.length > 0) {
        this.deleteSelections(servicesIdsToUnselect)
      }
      // set state of which service ids were selected to compare on next event emission
      this.selectedServiceIds = currentServiceIdsSelected
    },
    shouldDisplayAddressWithSuiteNumber(item) {
      return item.mail_forwarding_address && item.mail_forwarding_items && item.mail_forwarding_items.active
    },
    isFreeLimitedUse(item) {
      return (item.mail_forwarding_address && item.mail_forwarding_items
          && item.mail_forwarding_items.active === false
          && item.mail_forwarding_items.service_type === 'free_limited_use') ||
        (item.mail_forwarding_address && !item.mail_forwarding_items
          && item.product.subcategory === 'free_limited_use')
    },
    addressLine1Format(item) {
      if (item.product.subcategory === 'free_limited_use' && item.mail_forwarding_address) {
        return item.mail_forwarding_address.line1 + " " + (item.mail_forwarding_address.line2 ? item.mail_forwarding_address.line2 : "")
      } else {
        return item.mail_forwarding_address.line1 + " #" + item.mail_forwarding_items.suite_number
      }
    },
    allStatuses() {
      return [
        ...this.defaultStatuses(),
        'cancelled',
      ]
    },
    defaultStatuses() {
      return Object.values(OPERATING_STATUSES)
    },
  },
}
</script>

<style scoped lang="scss">
  .container-width {
    max-width: 1500px;
  }

  .services-page-component {
    ::v-deep(.select-auto-payable-dropdown .dropdown-toggle) {
      width: 150px;
      font-size: 12px !important;
      padding: 8px 16px 8px 8px;
    }
  }

  .services-table {
    font-size: 12px !important;
  }

  .ra-details-link {
    color: #30B2AE !important;
    text-decoration: underline !important;
  }

  .autopay-select {
    height: 40px;
    padding-right: 10px;
  }

  .autopay-select .custom-select {
    font-size: 12px !important;
    height: auto !important;
    max-height: 40px !important;
    min-height: 40px !important;
  }

  .autopay-select {
    &.select-loading select.custom-select {
      background: none !important;
    }
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
  }

  .autopay-spinner {
    &.select-loading {
      display: inline-block;
    }
    color: #bbb !important;
    display: none;
    position: absolute;
    right: 7px;
    top: 20px;
    span.spinner-border {
      width: 1.2rem !important;
      height: 1.2rem !important;
      span {
        border: 0.10em solid black !important;
      }
    }
  }

  @media only screen and (max-width: 660px) {
    .services-page-component {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      align-items: center;
    }
  }
</style>
