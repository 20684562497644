<template>
  <div class="select-auto-payable-dropdown">
    <b-dropdown id="scrollable-dropdown" :disabled="disabled">
      <template #button-content>
        <div class="custom-button-content">
          <div v-if="payableType === 'card'">
            <b-img :src="cardLogo(selectedAutopay.brand)" class="mr-2" />
            <b-img src="/images/darkCreditCards/fourDots.svg" class="mr-2" />
          </div>
          <div v-if="payableType === 'automatedClearingHouse'">
            <b-img src="/images/automatedClearingHouses/darkAch.svg" class="mr-2" />
            <b-img src="/images/darkCreditCards/fourDots.svg" class="mr-2" />
          </div>
          {{ selectedOptionText }}
        </div>
      </template>
      <div v-if="cards">
        <div class="header-section">
          <header>Credit/Debit Cards</header>
        </div>
        <b-dropdown-item
          v-for="(card, index) in cards"
          :key="card.id"
          :class="{ 'last-item': index === cards.length - 1 }"
        >
          <div
            class="dropdown-item-info"
            @mouseover="setHoverId(card.id)"
            @mouseout="setHoverId(null)"
            @click="handleDropdownItemClick(card, 'card')"
          >
            <b-img :src="cardLogo(card.brand)" class="mr-3" />
            <b-img src="/images/darkCreditCards/fourDots.svg" class="mr-1" />
            {{ card.last4 }}
          </div>
          <auto-pay-hover-selection
            :hover-id="hoverId"
            :self-payable="card"
            :service-payable-id="localSelectedAutopayId"
            :on-assign-callback="handleDropdownItemClick"
            :on-remove-callback="handleRemoveAutoPayable"
            payable-type="card"
          />
        </b-dropdown-item>
      </div>
      <div v-if="isPurchaseCanaryWebsite && automatedClearingHouses">
        <div class="header-section">
          <header>ACH/Bank Transfer</header>
        </div>
        <b-dropdown-item
          v-for="(ach, index) in automatedClearingHouses"
          :key="ach.id"
          :class="{ 'last-item': index === automatedClearingHouses.length - 1 }"
        >
          <div
            class="dropdown-item-info"
            @mouseover="setHoverId(ach.id)"
            @mouseout="setHoverId(null)"
            @click="handleDropdownItemClick(ach, 'automatedClearingHouse')"
          >
            <b-img src="/images/automatedClearingHouses/darkAch.svg" class="mr-3" />
            <b-img src="/images/darkCreditCards/fourDots.svg" class="mr-1" />
            {{ ach.bank_account_number.slice(-2) }}
          </div>
          <auto-pay-hover-selection
            :hover-id="hoverId"
            :self-payable="ach"
            :service-payable-id="localSelectedAutopayId"
            :on-assign-callback="handleDropdownItemClick"
            :on-remove-callback="handleRemoveAutoPayable"
            payable-type="automatedClearingHouse"
          />
        </b-dropdown-item>
      </div>
      <div v-if="!isVIP" class="header-section add-payment-method">
        <b-button
          class="add-payment-method-button"
          variant="primary"
          aria-label="add payment method button"
          @click="handleDropdownItemClick(`ADD`)"
        >
          Add Payment Method
        </b-button>
      </div>
    </b-dropdown>
  </div>
</template>
<script>

import { getDarkCardLogo } from '@/common/modules/cc'
import { mapGetters } from 'vuex'
import AutoPayHoverSelection from '@/components/Payments/AutoPay/AutoPayHoverSelection'

export default {
  name: 'SelectAutoPayable',
  components: { AutoPayHoverSelection },
  props: {
    selectedAutopayId: null,
    isVIP: Boolean,
    disabled: Boolean,
  },
  data () {
    return {
      hoverId: null,
      localSelectedAutopayId: null,
      payableType: null,
      selectedAutopay: null,
    }
  },
  computed: {
    ...mapGetters('paymentMethods', ['automatedClearingHouses', 'cards', 'selectedPaymentMethod']),
    ...mapGetters('website', ['website', 'isPurchaseCanaryWebsite']),
    selectedOptionText() {
      if (this.payableType === 'card') {
        const card = this.cards.find(card => card.id === this.localSelectedAutopayId)
        return card.last4
      } else if (this.payableType === 'automatedClearingHouse') {
        const ach = this.automatedClearingHouses.find(ach => ach.id === this.localSelectedAutopayId)
        return ach.bank_account_number.slice(-2)
      } else {
        return 'Not Selected'
      }
    },
    noPaymentMethodSelected() {
      return this.selectedOptionText === 'Not Selected'
    },
  },
  async mounted() {
    this.localSelectedAutopayId = this.selectedAutopayId
    this.setSelectedPayableType()
    this.setSelectedAutoPay()
  },
  methods: {
    setHoverId(hoverId) {
      this.hoverId = hoverId
    },
    setSelectedPayableType() {
      if (this.localSelectedAutopayId) {
        if (this.cards.some(c => c.id === this.localSelectedAutopayId)) {
          this.payableType = 'card'
        } else if (this.automatedClearingHouses.some(a => a.id === this.localSelectedAutopayId)) {
          this.payableType = 'automatedClearingHouse'
        }
      } else {
        this.payableType = null
      }
    },
    setSelectedAutoPay() {
      if (this.localSelectedAutopayId) {
        if (this.payableType === 'card') {
          this.selectedAutopay = this.cards.find(c => c.id === this.localSelectedAutopayId)
        } else if (this.payableType === 'automatedClearingHouse') {
          this.selectedAutopay = this.automatedClearingHouses.find(a => a.id === this.localSelectedAutopayId)
        }
      } else {
        this.selectedAutopay = null
      }
    },
    cardLogo(brand) {
      return getDarkCardLogo(brand)
    },
    handleRemoveAutoPayable() {
      this.payableType = null
      this.selectedAutopay = null
      this.localSelectedAutopayId = null
      this.$emit('dropdownItemSelected', null)
    },
    handleDropdownItemClick(selectedItem, payableType) {
      if (selectedItem === 'ADD') {
        this.$emit('dropdownItemSelected', 'ADD')
      } else {
        this.payableType = payableType
        this.localSelectedAutopayId = selectedItem.id
        this.setSelectedAutoPay()
        this.$emit('dropdownItemSelected', selectedItem.id)
      }
    },
  },
}
</script>
<style scoped lang="scss">

  .select-auto-payable-dropdown {

    .dropdown-item-info {
      padding: .5rem 1rem;
      width: 100%;
      height: 100%;
    }

    .custom-button-content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .header-section {
      border-bottom: 1px solid var(--Gray-3, #D0D5E2);
      background: var(--Gray-2, #EBEDF3);

      header {
        display: inline-flex;
        padding: 5px 8px;
        align-items: center;
        gap: 16px;
        color: #363636;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 200% */
      }
    }

    ::v-deep(.dropdown-toggle) {
      display: flex;
      width: 200px;
      padding: 16px 16px 18px 8px;
      justify-content: flex-start;
      align-items: flex-start;

      color: #363636;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      border-radius: 4px;
      border: 1px solid var(--Gray-3, #D0D5E2);
      background: #FFF;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    ::v-deep(.dropdown-toggle:active) {
      color: #363636;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      border-radius: 4px;
      border: 1px solid var(--Gray-3, #D0D5E2);
      background: #FFF;
    }

    ::v-deep(.dropdown-toggle:disabled) {
      color: #6c757d;
      background-color: #e9ecef;
    }

    ::v-deep(.dropdown-toggle:focus) {
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
    }

    ::v-deep(.dropdown-toggle::before) {
      content: "";
      margin-top: 12px;
      margin-right: 8px;

      border-top: 0.275em solid;
      border-right: 0.275em solid transparent;
      border-bottom: 0;
      border-left: 0.275em solid transparent;
    }

    ::v-deep(.dropdown-toggle::after) {
      content: none !important;
    }

    ::v-deep(#scrollable-dropdown .dropdown-menu) {
      max-height: 150px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        width: 0;
        background: #FFF;
      }

      &::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        border-radius: 4px;
      }
    }

    ::v-deep(.dropdown-menu.show) {
      border-radius: 4px;
      border: 1px solid var(--Gray-3, #D0D5E2);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

      display: block;
      width: 280px;
      padding-top: 0;
      padding-bottom: 0;
    }
    ::v-deep(a.dropdown-item) {
      padding-left: 0 !important;
      padding-right: 0 !important;
      width: 100% !important;
      justify-content: space-between;
      align-items: center;
    }
    ::v-deep(.dropdown-item) {
      display: inline-flex;
      width: 100%;
      border-bottom: 1px solid var(--Gray-2, #EBEDF3);
      background: #FFF;
      color: #363636;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      transition: background-color 0.3s;
    }

    ::v-deep(.dropdown-item:hover) {
      background: darken(#FFF, 5%);
    }

    .add-payment-method {
      display: flex;
      padding: 10px 8px 8px 8px;
      justify-content: center;
      align-items: center;
      align-self: stretch;

      .add-payment-method-button {
        display: flex;
        padding: 4px 16px;
        align-items: center;
        gap: 4px;

        border-radius: 4px;
        background: #29A9A4;
      }
    }

    .remove-autopay {
      display: flex;
      padding: 1px 8px;
      justify-content: center;
      align-items: center;
      align-self: stretch;

      .remove-autopay-button {
        color: #E57880;

        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 200% */
      }
    }

    // change this to be the last item in group
    .last-item {
      border-bottom: 1px solid var(--Gray-3, #D0D5E2);
    }
  }
</style>
